import { Factors } from 'common/types/bundle';

/** Amount of some liquid */
export type Measurement = {
  unit: string; // e.g. 'ul'
  value: number; // e.g. 50
};

export function isMeasurement(obj: any): obj is Measurement {
  const asMesurement = obj as Measurement;
  return asMesurement.unit !== undefined && asMesurement.value !== undefined;
}

/**
 * Amount of some liquid, including an estimated error.
 * Because liquid handling robots are not 100% precise.
 */
export type MeasurementWithStdDev = Measurement & {
  /**
   * Estimated error in the volume. Because liquid handling robots are not
   * 100% precise.
   */
  stddev?: number;
};

/**
 * These are the raw actions that come from actions.json
 */
export type Action = TransferAction | PromptAction | MoveLabwareAction | ErrorAction;

/**
 * Represents contents of the `layout.json` file.
 */
export type Deck = {
  before: DeckState;
  after: DeckState;
  version: string;
};

// A state of the deck.
export type DeckState = {
  positions: {
    [deckPositionName: string]: DeckPosition;
  };
  // rich_annotations is an optional array of accompanying data which help inform the look of the mix preview
  rich_annotations?: {
    [annotationName: string]: RichAnnotation;
  };
};

// A state of a slot on the deck
export type DeckPosition = {
  position: Coordinates2 | Coordinates3; // Position of the slot on the deck
  size: Coordinates2; // Size of the slot
  label?: string; // Meaningful name to display for slots of significance (e.g. carriers)
  /**
   * Deck item, if this slot contains a deck item
   *
   * @deprecated Antha now supports stacking multiple labware in a given location. Use
   * `items` instead of `item`.
   */
  item?: DeckItem;
  /**
   * Stack of labware items at this deck position. Although the layout.json structure
   * allows arbitrary stacking of deck items, in practice we only stack one deck item
   * and/or one lid.
   */
  items?: DeckItem[];
};

// This represents the set of extra annotations which can be found in the array
export enum RichAnnotationType {
  GRID = 0, // The grid annotation, used to represent the grid sections which LH's use to affix carriers and labware
  EXTRA_DECK = 1, // The extra deck, a set of positions which live on a LH deck which can be used to represent arbitrary things
  STAGE = 2, // The stage annotation divides the Simulation preview workspace into sections for multi-stage simulations
  GROUP = 3, // The group of deck positions, used to represent source/destination plates in dispenser multi I/O plates experiment
}

// The tagged union of the data for a rich annotation
// TODO(SYN-3911) Add the data for nest annotations (e.g. 61mm vs 7mm)
export type RichAnnotation =
  | ExtraDeckAnnotationType
  | GridAnnotationType
  | PositionGroupAnnotationType
  | StageAnnotationType;

type GridAnnotationType = {
  annotation_type: RichAnnotationType.GRID;
  grid_annotation: GridAnnotation;
};

type ExtraDeckAnnotationType = {
  annotation_type: RichAnnotationType.EXTRA_DECK;
  extra_deck_annotation: ExtraDeckAnnotation;
};

type StageAnnotationType = {
  annotation_type: RichAnnotationType.STAGE;
  stage_annotation: StageAnnotation;
};

type PositionGroupAnnotationType = {
  annotation_type: RichAnnotationType.GROUP;
  positions_group_annotation: PositionGroupAnnotation;
};

export type PositionGroupAnnotation = {
  description: string;
  heading_colour: string;
  label: string;
  position: Coordinates2 | Coordinates3;
  size: Coordinates2;
};

export type GridAnnotation = {
  position: Coordinates2; // Position of the grid section on the deck
  size: Coordinates2; // Size of grid section
  label: string; // The name of the grid according to the backend
  important: boolean; // A flag to indicate an important grid section
  left: boolean; // A flag to indicate the left most grid section of the set of annotations
  right: boolean; // A flag to indicate the right most grid section of the set of annotations
  offDeck: boolean; // A flag to indicate if the grid section actually hangs of the edge of the deck (useful for plate readers/washers)
};

export type ExtraDeckAnnotation = {
  position: Coordinates3; // Position of the annotation on the deck (Z is used for z ordering)
  size: Coordinates2; // Size of the annotation
  label?: string; // An optional label centred in the annotation
};

export type StageAnnotation = {
  x_mm: number;
  width_mm: number;
};

export type DeckItem = Cap | Plate | Lid | Tipbox | Tipwaste;

export type Coordinates2 = {
  x_mm: number;
  y_mm: number;
};

export type Coordinates3 = {
  x_mm: number;
  y_mm: number;
  z_mm: number;
};

// - cylinder is a circular well
// - cuboid is a rectangular well
export type WellType = 'cylinder' | 'cuboid';

type DeckItemCommonProps = {
  id: string; // id of the full standalone Plate or Tipbox object
  name: string;
  /**
   * e.g. "DL10 Tip Rack (PIPETMAX 8x20)"
   */
  type: string;
  /**
   * e.g. "Gilson"
   */
  manufacturer: string;
  /**
   * The color to draw this element, in any CSS-compatible format (eg, "white",
   * "#fdf3da")
   */
  color?: string;
  description: string;
  /**
   * 3d dimensions of the plate / tipbox
   */
  dimensions: Coordinates3;
  version?: number;
};

type DeckItemWithWellsProps = DeckItemCommonProps & {
  rows: number;
  columns: number;
  /**
   * All deck items have geometry, even a tipwaste
   * (tipwaste is represented in the backend as one large well)
   */
  well_type: WellType;
  well_dimensions: Coordinates3;
  /**
   * The centrepoint of the well at the top right of the plate.
   */
  well_start: Coordinates3;
  /**
   * Spacing between well centrepoints (i.e., the row height and column width).
   * For example, the distance between the centrepoint of A1 and the centrepoint
   * of A2.
   */
  well_offset: Coordinates2;
};

export type Plate = DeckItemWithWellsProps & {
  kind: 'plate';
  contents?: PlateContentsMatrix;
  residual_volume: Measurement;
  // The contents of the layer below this one, for plate-based mixing.
  contentsBelow?: PlateContentsMatrix;
};

export type Tipbox = DeckItemWithWellsProps & {
  kind: 'tipbox';
  // The positions at which tips are not present, at the start of the job
  missing_tips?: {
    row: number;
    col: number;
  }[];
};

export type Tipwaste = DeckItemWithWellsProps & {
  kind: 'tipwaste';
  // The number of discarded tips in the waste
  discarded_tips?: number;
};

/**
 * Lids are used to cover plates to prevent liquid evaporation and contamination. A lid
 * can either be on a plate or occupy an empty deck space. A plate with a lid is modelled
 * in layout.json as a deck space with two items; item 1 is the plate and item 2 is the
 * lid.
 *
 * A lid can be autonomously moved to or from a plate by the liquid handler as part of a
 * move_plate (due to be renamed to move_labware) action.
 *
 * Design doc:
 * https://paper.dropbox.com/doc/Plate-Lids-Phase-1--BFuZZjrIw_hHrOpGP9q4LJgPAg-VFpSfoVK0PPpL3Ff1BGF6
 */
export type Lid = DeckItemCommonProps & {
  kind: 'lid';
};

/**
 * Caps will behave in the same way as lids: they are on tube racks by default, are removed before the
 * tube rack (which operates similar to a plate) is used for liquid handling then replaced immediately
 * after liquid handling is complete.
 *
 * A cap can be autonomously moved to or from a plate by the liquid handler as part of a
 * move_plate (due to be renamed to move_labware) action.
 *
 * Design doc:
 * https://docs.google.com/document/d/1OYtYcuQ8S7QaCPEussI-S9vMBWmmPF4ucZrpEAmuuYU/edit?usp=sharing
 */
export type Cap = DeckItemCommonProps & {
  kind: 'cap';
};

// Sparse 2d dictionary that describes the contents of a single plate.
// Example:
// {
//   0: { 2: {...contentsOfWellC1}, 3: {...contentsOfWellD1} },
//   2: {...column3}
// }
export type PlateContentsMatrix = {
  [column: number]: { [row: number]: WellContents };
};

/**
 * Represents a logical transfer, which can contain multiple child transfers.
 * This is what we get from the backend - compare with `TransferStepExpanded`.
 */
type TransferAction = {
  kind: 'transfer';
  /**
   * Groups several transfers that logically belong together.
   * For example, a logical transfer of 120ul doesn't fit into a single tip
   * and has to be split into two children: 100ul transfer, and 20ul transfer.
   */
  children: (
    | ParallelTransferAction
    | TipAction
    | RefreshTipboxesAction
    | TipWashAction
    | MoveLabwareAction
    | PromptAction
    | HighlightAction
  )[];
} & ActionCommonProps;

// A low-level step the machine will physically execute.
// This moves 1..n liquids, using 1..n pipettes.
export type ParallelTransferAction = {
  kind: 'parallel_transfer';
  channels: { [channel: string]: SingleTransfer };
} & ActionCommonProps;

// Specifies tips used by a transfer
export type TipAction = {
  kind: 'load' | 'unload';
  // The head to load tips to or unload tips from
  head: number;
  // The position(s) to load tips from or drop tips to for each channel
  channels: { [channel: number]: WellLocationOnDeckItem };
} & ActionCommonProps;

/**
 * TipWash describes the washing of fixed tips. There can be multiple cleaner positions
 * used in a single tip wash action. For example, tecan may wash fixed tips
 * using a deep wash cleaner and then dispense into a waste, yielding two
 * positions (deep cleaner and waste).
 */
export type TipWashAction = {
  kind: 'tip_wash';
  // The head with tips to be washed
  head: number;
  // The deck positions of the tip cleaners.
  tip_wash_positions: TipWashPosition[];
} & ActionCommonProps;

/**
 * HighlightAction is a generic idea which can be re-used by a liquid handler to indicate something of importance
 * happening on deck. For example, a gripper exchange.
 */
export type HighlightAction = {
  kind: 'highlight';
  // The short description used to describe the action.
  title: string;
  // The associated description of what is happening to the LH at the specified sites.
  message: string;
  // The list of positions to be highlighted.
  positions: HighlightPosition[];
} & ActionCommonProps;

// A mixed position is either a string address, or the key to a corresponding rich annotation.
export type HighlightPosition = AddressPosition | RichAnnotationKey;

export type AddressPosition = {
  kind: 'address';
  position: string;
};

export type RichAnnotationKey = {
  kind: 'rich_annotation_key';
  key: string;
};

export type TipboxToRefresh = {
  kind: 'tipbox_to_refresh';
  id: string;
};

/**
 * Specified the deck position (e.g. TecanPos_1_3) and label (e.g. 'waste') of
 * labware for cleaning fixed tips.
 */
type TipWashPosition = {
  position: string;
  label: string;
};

/** Specifies action to refresh certain tipboxes (identified by id and tip type) */
export type RefreshTipboxesAction = {
  kind: 'tipbox_refresh';
  tipboxes_to_refresh: TipboxToRefresh[];
} & ActionCommonProps;

/**
 * An action that specifies labware (such as plates or lids) physically moving to a
 * different location to another place on the deck.
 */
export type MoveLabwareAction = {
  /**
   * This will in future be renamed to "move_labware".
   */
  kind: 'move_plate';
  /** Message to show to the user, for example "Move Plate with iSWAP arm" */
  message: string;
  /**
   * If true, the labware move will be made automatically without the need for user
   * interaction, for example using a robot arm.
   */
  automated: boolean;
  /**
   * Some liquid handlers (e.g. Tecan Evo) support multiple grippers (aka arms) that can
   * move labware around.
   */
  gripper_name?: string;
  /** Describes the labware movements. */
  effects: readonly MoveLabwareEffect[];
} & ActionCommonProps;

/**
 * Part of `MoveLabwareAction`. Specifies a single piece of labware, such as a plate or lid,
 * to physically move from one deck location to another.
 */
export type MoveLabwareEffect = {
  /**
   * The name of the labware to move, for example "AliquotPlate". Note this returns the
   * name of the labware, not the ID as the property name suggests.
   *
   * This will be soon renamed `labware_name`.
   */
  plate_id: string;
  /**
   * The name of the deck position name that the plate is moved to,
   * for example "hx://BioTek_405TS_Landscape_0001/1"
   */
  move_to: string;
  /** The final rotation of the plate in the clockwise direction, in degrees. */
  rotation: number;
};

type ActionCommonProps = {
  /**
   * The type of action, e.g. prompt, transfer
   */
  kind: ActionKind;
  /**
   * Estimate of time taken for this action, in seconds
   */
  time_estimate: number;
  /**
   * Estimate of total time taken for the run so far after this action has completed, in
   * seconds
   */
  cumulative_time_estimate: number;
};

// Type of liquid handling action.
export type ActionKind =
  | 'prompt'
  | 'error'
  | 'move_plate'
  | 'tipbox_refresh'
  | 'load'
  | 'unload'
  | 'transfer'
  | 'parallel_transfer'
  | 'parallel_dispense'
  | 'tip_wash'
  | 'highlight';

// Transfer liquid from one well to another one well, using one head of
// the robot.
export type SingleTransfer = {
  from: WellContentUpdate;
  /**
   * This is an array to facilitate multi-dispense, that is aspirating and then dispensing
   * the contents into multiple different wells.
   *
   * An entry may be null if this channel is not doing anything during this dispense.
   */
  to: (DestContentUpdate | null)[];
  /**
   * Volume being transferred
   */
  volume: MeasurementWithStdDev;
  /**
   * Some of the `volume` sticks to the tip and gets thrown away. Therefore, the
   * volume that arrives in the target well is `volume` - `wasted`.
   */
  wasted: Measurement;
  /**
   * Liquid handling policy, e.g. 'dna', 'water' meaning "handle like dna, or
   * handle like water". Tells the robot whether to go deep into the liquid when
   * dispensing, or whether to dispense slowly (or quickly) from above the liquid
   * level, for example.
   *
   * This property of each liquid transfer matters a lot to users and they look
   * at it to make sure the robot is going to handle the liquid the way they
   * expect.
   *
   * IDEA: Based on the policy name, we can fetch more details about the policy
   * when an edge is clicked, and display the policy info. The policy info is
   * stuff like "dispense above liquid". It's unclear whether there is an
   * existing backend endpoint for this.
   */
  policy: string;
  /**
   * The head to load tips to or unload tips from
   */
  head: number;
  asp: PipettingOptions;
  dsp: PipettingOptions & {
    /**
     * Whether or not a touchoff is performed
     */
    touchoff: boolean;
    /**
     * Blowout means try to push out the last bit of liquid remaining in the tip
     */
    blowout: BlowoutInfo;
  };
  /**
   * The experiment stage that this operation belongs to.
   */
  stage_name?: string;
  /**
   * ID of each rule applied to this transfer. The rule can be looked up in `rules` at the
   * root of the actions.json.
   *
   * If multiple rules have consequences affecting the same property then the last of
   * those rules applies. For example, if we have two rules [RuleA, RuleB] which both have
   * a consequence on blowout volume, the blowout volume of RuleB overrides that of RuleA.
   */
  rule_ids?: string[];
};

export type BlowoutInfo = {
  volume: Measurement;
  height?: PipettingHeight;
  // some device plugins cannot control the flow rate and so don't set it
  flow_rate?: Measurement;
};

export type PipettingHeight = {
  value: number;
  unit: string;
  reference:
    | 'well_bottom'
    | 'well_top'
    | 'liquid_level'
    | 'liquid_level_auto'
    | 'liquid_level_on';
};

// Describes options about how pipetting is carried out
export type PipettingOptions = {
  /**
   * Either exact pipetting height in the form of reference point + offset relative to it. Or just
   * the where pipetting takes place.
   */
  height?: PipettingHeight;
  // Deprecated: will only be populated by old simulations and is kept so that they're still
  // viewable on the new platform.
  zone?: string;
  // some device plugins cannot control the flow rate and so don't set it
  flow_rate?: Measurement;
  /**
   * Some liquid handlers can be configured to sense the level of liquid within the well.
   * This is called Liquid Level Detection (LLD) is used to prevent tips entering the
   * liquid which cause cross contamination between wells.
   */
  liquid_level_detect?: boolean;
  /**
   * Some liquid handlers can raise or lower the tip as the level within a well changes
   * during aspirating or dispensing. This is called Liquid Level Follow (LLF).
   */
  liquid_level_follow: boolean;
  mixing?: MixInfo;
  // some devices store detailed parameters about pipetting behaviour separately
  // from instructions for a particular run as "liquid classes".
  // This name tells users which of these device-managed classes is selected for
  // the given operation.
  device_liquid_class_name?: string;
};

export type MixInfo = {
  // The number of mix cycles that were carried out
  cycles: number;
  volume: Measurement;
  /**
   * Either exact pipetting height in the form of reference point + offset relative to it. Or just
   * the where pipetting takes place.
   */
  height?: PipettingHeight;
  // Deprecated: will only be populated by old simulations and is kept so that they're still
  // viewable on the new platform.
  zone?: string;
  // some device plugins cannot control the flow rate and so don't set it
  flow_rate?: Measurement;
  /**
   * @see PipettingOptions.liquid_level_detect
   */
  liquid_level_detect?: boolean;
  /**
   * @see PipettingOptions.liquid_level_follow
   */
  liquid_level_follow: boolean;
};

/**
 * Describes the new state of a well at a given location after an action has
 * taken place.
 */
export type WellContentUpdate = {
  loc: WellLocationOnDeckItem;
  new_content: WellContents;
  /**
   * Used in multidispense to indicate amount being dispensed from the tip to
   * the new state.
   */
  volume_change: Measurement;
};

/**
 * Describes the new state of a well after the given volume of liquid has been
 * dispensed.
 */
export type DestContentUpdate = {
  /**
   * Exists in filtration transfers only. Describes a filter (e.g. a robocolumn)
   * that a liquid passed through. In this case `loc` describes the locations
   * where liquid has dropped from the filter.
   */
  filter?: FilterColumnContentUpdate;
  /**
   * Volume of liquid left in the tip after dispense of `volume_change`
   */
  volume_in_tip?: MeasurementWithStdDev;
} & WellContentUpdate;

export type FilterColumnContentUpdate = {
  /**
   * Location of the filter. Liquid is dispensed at this location.
   */
  loc: WellLocationOnDeckItem;
};

/**
 * Position of a well on a plate or tip box, e.g. row=2, col=0 (that is, C1)
 */
export type WellLocation = {
  /**
   * Row number (zero-indexed)
   */
  row: number;
  /**
   * Column number (zero-indexed)
   */
  col: number;
};

/**
 * Location somewhere on the deck, like "C2 in source plate" or "A1 in tipbox"
 */
export type WellLocationOnDeckItem = {
  deck_item_id: string;
} & WellLocation;

/**
 * This is shared both by normal wells and by Robocolumns filter matrix "wells"
 * / columns.
 */
type WellContentsBase = {
  /**
   * The type of contents summary:
   * - liquid_summary: any substance
   * - filter_matrix_summary: a resin through which a liquid can be passed for filtering
   *   out certain components (e.g. proteins)
   *
   * This is undefined on older simulations, in which case it can be assumed this is a
   * liquid.
   */
  kind?: string;

  id: string;
  /**
   * User-friendly name of the liquid. Important to scientists, and especially
   * useful when this is a mix of several liquids. Example: 'Theta 2'.
   */
  name?: string;
  /**
   * The liquid's type is the primary attribute which affects the policy used
   * when moving the liquid. Example: 'water', 'DNA'
   */
  type?: string;
  /** Amount of the liquid in the well. */
  total_volume: Measurement;
  /**
   * The liquid in the well can be a mix of several different liquids, e.g.
   * 'MasterMix' and 'dna'.
   * This is displayed as "Source Liquids" in the UI.
   */
  sub_liquids?: SubLiquid[];
  /**
   * The liquid can have solutes - substances that are dissolved into it - here
   * we list them.
   * This is displayed as "Concentrations" in the UI.
   */
  solutes?: Solute[];
  /**
   * An ordered list of metadata tags associated with this liquid
   */
  tags?: DataTag[];
  // exists in older JSON, just a list of the names of sub_liquids shown here
  // so as not to break backwards compatibility (15/01/2020)
  // TODO: remove this once we no longer need to support JSON without
  //       sub_liquids or solutes
  components?: Component[];
  /**
   * A liquid may have a hex colour predefined in the layout file. If there is
   * no colour specified, antha ui will pick a colour.
   */
  color?: string;
  /**
   * Design factors for this well, if it is part of a DOE.
   */
  designFactors?: {
    run: number;
    values: DOEDesignRun;
  };
};

/**
 * Describes a liquid within an well
 */
export type Liquid = {
  kind?: 'liquid_summary';
} & WellContentsBase;

/**
 * Describes a filter matrix (aka resin) within a RoboColumn
 */
export type FilterMatrix = {
  kind: 'filter_matrix_summary';
} & WellContentsBase;

/**
 * Describes a filter matrix (aka resin) within a Filteration Plate
 */
export type FiltrationPlateMatrix = {
  kind: 'filtration_plate_matrix_summary';
} & WellContentsBase;

export type Mixture = {
  kind: 'mixture_summary';
} & WellContentsBase;

/**
 * Describes a liquid in a layer within plate-based mixing.
 */
export type LiquidLayer = {
  kind: 'liquid_layer';
  overallConcentration?: Measurement;
  total_volume: Measurement | undefined;
  isGroup: boolean;
} & Omit<WellContentsBase, 'total_volume'>;

export type WellContents =
  | Liquid
  | LiquidLayer
  | FilterMatrix
  | FiltrationPlateMatrix
  | Mixture;

/**
 * DataTag is a piece of meta data associated with the contents of a well - a
 * liquid.
 * Currently unformatted string data and floating point numerical data are
 * supported.
 */
export type DataTag = {
  /**
   * The identifier for this data tag, may not be unique
   * e.g. 'AntibodyName'
   */
  label: string;
  /**
   * The value for this DataTag if it's a 'categorical', that is, non-numerical
   * piece of data.
   * Should not be present if 'float' is present.
   */
  value_string?: string;
  /**
   * The value for this DataTag if it's a 'numerical' piece of data.
   * Should not be present if 'string' is present
   */
  value_float?: number;
};

// A liquid in a well can be a mix of several different liquids.
// We call each of these liquids a "component". For example, one of the
// components would be "MasterMix".
export type SubLiquid = {
  // e.g. "MasterMix"
  name: string;
  volume: Measurement;
};

// A liquid in a well can have substances (typically solids) disolved in it,
// this is called a Solute
export type Solute = {
  // e.g. Sodium Choloride
  name: string;
  concentration: Measurement;
};

// A legacy type
export type Component = {
  name: string;
};

/**
 * Robot stops and prompts user to do some manual action.
 * In response, user does that manual action and when ready, dismisses the prompt allowing robot to continue execution.
 *
 * e.g. "Please put the plate in an incubator for 5 minutes, then return the plate on the deck".
 */
export type PromptAction = {
  kind: 'prompt';
  /**
   * Time period after which the work will resume automatically
   */
  duration_seconds?: number;
  /**
   * Message copy displayed in the prompt area
   * e.g. 'Please put the plate in an incubator for 5 minutes'
   */
  message: string;
  /**
   * Some prompts cause the user to manually change the state:
   * e.g. we might prompt the user to wash the plate which has the effect of removing all the liquid from it
   */
  effects?: WellContentUpdate[];
} & ActionCommonProps;

// Error action records that a fatal planning error happened immediately after the
// previous step
export type ErrorAction = {
  kind: 'error';
  message: string;
} & ActionCommonProps;

/**
 * Describes a liquid handling rule that has been applied to a transfer. A rule comprises
 * some conditions under which the rule applies and consequences of the rule.
 *
 * Examples:
 * - If transfer volume >= 50 ul then post-mix volume should be 50ul.
 * - If liquid type is DispenseAboveLiquidMulti then blowout volume should be 50ul.
 * - Under all conditions (`conditions` = null), aspirate reference should be well_bottom,
 *   aspirate liquid level detect (LLD) should be true, and aspirate flow rate should be
 *   10ul/s.
 */
export type Rule = {
  /**
   * Name given to the rule, meaningful to users
   */
  name: string;
  /**
   * Where was the rule defined:
   * - user: a rule supplied by the user
   * - system: a rule hardcoded into the system
   * - element: a rule created by an element
   * - override: a rule created by a device, usually to avoid obvious errors
   */
  source: 'user' | 'system' | 'element' | 'override';
  /**
   * Defines when the rule is active. `null` if this rule always applies.
   */
  conditions: RuleConditionValue[] | null;
  /**
   * Defines what happens when the rule is active
   */
  consequences: { [property: string]: RuleConsequenceValue };
};

type RuleConditionValue = {
  variable: string;
  operator?: string;
  value?: Measurement | number;
  values?: string[];
};

export type RuleConsequenceValue =
  | VolumeConsequence
  | Measurement
  | string
  | string[]
  | number
  | boolean;

export type VolumeConsequenceKind =
  | 'constant'
  | 'maximum'
  | 'minimum'
  | 'scaled'
  | 'property';

export type VolumeConsequenceCommon = {
  kind: VolumeConsequenceKind;
};

export type ConstantVolumeConsequence = {
  kind: 'constant';
  constant_volume: Measurement;
} & VolumeConsequenceCommon;

export type MaxVolumeConsequence = {
  kind: 'maximum';
  volumes: VolumeConsequence[];
} & VolumeConsequenceCommon;

export type MinVolumeConsequence = {
  kind: 'minimum';
  volumes: VolumeConsequence[];
} & VolumeConsequenceCommon;

export type ScaledVolumeConsequence = {
  kind: 'scaled';
  volume_to_scale: VolumeConsequence;
  factor: number;
} & VolumeConsequenceCommon;

export type PropertyVolumeConsequence = {
  kind: 'property';
  property: string;
} & VolumeConsequenceCommon;

export type VolumeConsequence =
  | ConstantVolumeConsequence
  | MaxVolumeConsequence
  | MinVolumeConsequence
  | ScaledVolumeConsequence
  | PropertyVolumeConsequence;

export type DOEDesignRun = Record<string, string | number>;

export type DOEDesign = {
  factors: Factors;
  runs: DOEDesignRun[];
};

export type VolumeOrConcentration =
  | {
      volume: Measurement;
    }
  | {
      concentration: Measurement;
    };
