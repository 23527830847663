import React, { useMemo } from 'react';

import Typography from '@mui/material/Typography';

import NoEntitiesBanner from 'common/assets/NoEntitiesBanner';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export enum MessageType {
  EMPTY_TAB,
  NO_FILTER_RESULTS,
}

export type Entity =
  | 'workflows'
  | 'example workflows'
  | 'protocol runs'
  | 'executions'
  | 'DOEs'
  | 'drafts'
  | 'incoming methods or workflows'
  | 'incoming workflows'
  | 'simulations'
  | 'templates'
  | 'files'
  | 'datasets'
  | 'experiments'
  | 'tutorials'
  | 'devices'
  | 'plate types'
  | 'liquid policies'
  | 'protocols';
/**
 * Message shown when no entities exist in the org.
 */
export function NoEntitiesMessage({
  entityName,
  messageType,
  searchQuery,
  secondaryText,
}: {
  entityName: Entity;
  messageType?: MessageType;
  searchQuery?: string;
  secondaryText?: string;
}) {
  const classes = useStyles();

  const { primaryText, secondaryTextCopy } = useMemo(() => {
    switch (messageType) {
      case MessageType.EMPTY_TAB:
        return {
          primaryText: `Your ${entityName} tab is currently empty.`,
          secondaryTextCopy: secondaryText ?? `All ${entityName} will appear here.`,
        };

      case MessageType.NO_FILTER_RESULTS:
        return searchQuery
          ? {
              primaryText: `No search results have been found for "${searchQuery}".`,
              secondaryTextCopy: secondaryText ?? 'Please try again.',
            }
          : {
              primaryText: `No ${entityName} found.`,
            };

      default:
        return {
          primaryText: `No ${entityName} found.`,
        };
    }
  }, [entityName, messageType, searchQuery, secondaryText]);

  return (
    <div className={classes.noEntitiesMessageAndGraphic}>
      <div className={classes.noEntitiesMessage}>
        <Typography variant="h5" color="textPrimary">
          {primaryText}
        </Typography>
        {secondaryTextCopy && (
          <Typography variant="h5" color="textSecondary">
            {secondaryTextCopy}
          </Typography>
        )}
      </div>
      <NoEntitiesBanner className={classes.noEntitiesGraphic} variant="blue" />
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  noEntitiesMessageAndGraphic: {
    textAlign: 'center',
    width: '100%',
  },
  noEntitiesGraphic: {
    maxWidth: '370px',
  },
  noEntitiesMessage: {
    margin: theme.spacing(9, 0, 7, 0),
  },
}));
