import { isDefined } from 'common/lib/data';

export type Schema = {
  inputs?: SchemaInput[];
  outputs?: SchemaOutput[];
};

/**
 * returns a copy of schema with any inputs or outputs which refer to the element with
 * the given ID removed
 */
export function removeElementsFromSchema(
  schema: Schema,
  elementIds: Set<string>,
): Schema {
  const filter = function (p: Path): boolean {
    const eId = getElementId(p);
    return !eId || !elementIds.has(eId);
  };
  return {
    inputs: schema.inputs?.filter(i => filter(i.path)),
    outputs: schema.outputs?.filter(o => filter(o.path)),
  };
}

/**
 * updates the default value for any affected schema inputs in response to the element
 * parameter value being updated.
 */
export function updateDefaultValue(
  inputs: SchemaInput[],
  elementId: string,
  parameterName: string,
  newValue: any,
) {
  const idsToDelete: string[] = [];
  inputs.forEach(input => {
    if (!isPathToParameter(input.path, elementId, parameterName)) return;

    // if there are extra keys, we need to check that they exist in the
    // new value, otherwise we have to drop the input
    const extraKeys = getElementExtraKeys(input.path)!;
    const newDefault = extractValue(extraKeys, newValue);
    if (newDefault === undefined) {
      idsToDelete.push(input.id);
    } else {
      input.default = newDefault;
    }
  });

  return inputs.filter(input => !idsToDelete.some(id => id === input.id));
}

function extractValue(keys: string[], value: any): any {
  const key = keys.shift();
  // we can't go deeper if value is undefined or we ran out of keys
  if (value === undefined || key === undefined) {
    return value;
  }
  if (Array.isArray(value)) {
    const index = parseInt(key);
    return extractValue(keys, value[index]);
  }
  // we also need to check objectness because it's possible to index
  // strings with a string!
  //    "hello"["0"]
  if (typeof value === 'object' && value !== null) {
    return extractValue(keys, value[key]);
  }
}

export type SchemaInput = {
  /**
   * identifier for this input, which is unique within the inputs of this workflow
   */
  id: string;
  /**
   * identifies the type of the input
   */
  typeName: string;
  /**
   * path is used to identify the location within the workflow where inputs should
   * be placed.
   */
  path: Path;
  /**
   * the default value for the input if no user input is provided
   */
  default: any;
  /**
   * contextId groups related inputs by element in cases where input components require
   * knowledge of the values of other inputs within the same element
   */
  contextId?: string;
};

export type SchemaOutput = {
  /**
   * identifier for this output, which is unique within the outputs of this workflow
   */
  id: string;
  /**
   * identifies the type of the output, and thus how computed outputs should be displayed
   */
  typeName: string;
  /**
   * path is used internally to identify the location within the workflow where outputs are
   * read from. This is handled internally and so there should be no need for client code
   * to interact with this field directly.
   */
  path: ElementPath;
};

/**
 * The Path type identifies a location within a workflow. It should not normally be necessary
 * inspect the value as reading and writing of values from the workflow is handled in antha-core.
 */
export type Path = ElementPath | StageDevicesPath;

export function isElementPath(path: Path): path is ElementPath {
  return path.length >= 3 && path[ElementPathIndex.LABEL] === 'element';
}

export function isPathToParameter(
  path: Path,
  elementId: string,
  paramName: string,
): path is ElementPath {
  return (
    isElementPath(path) &&
    path[ElementPathIndex.ELEMENT_INSTANCE_ID] === elementId &&
    path[ElementPathIndex.PARAM_NAME] === paramName
  );
}

export function getElementId(path: Path): string | undefined {
  if (isElementPath(path)) {
    return path[ElementPathIndex.ELEMENT_INSTANCE_ID];
  }
  return;
}

export function getElementParameterName(path: Path): string | undefined {
  if (isElementPath(path)) {
    return path[ElementPathIndex.PARAM_NAME];
  }
  return;
}

export function getElementExtraKeys(path: Path): string[] | undefined {
  if (isElementPath(path)) {
    return path.slice(ElementPathIndex.EXTRA_KEYS_START);
  }
  return;
}

export function arePathsEqual(a: Path, b: Path) {
  if (a.length !== b.length) {
    return false;
  }
  return a.every((aValue, index) => aValue === b[index]);
}

const enum ElementPathIndex {
  LABEL = 0,
  ELEMENT_INSTANCE_ID = 1,
  PARAM_NAME = 2,
  EXTRA_KEYS_START = 3,
}

/**
 * Describes the path of an element input or output.
 */
export type ElementPath = [
  label: 'element',
  elementInstanceId: string,
  paramName: string,
  ...extraKeys: string[],
];

/**
 * creates a new ElementPath which can be read by antha-core.
 *
 * 'elementId' and 'parameterName' are required and identify the element and parameter
 * within the element (this may be an input or output).
 *
 * 'extraKeys' is relevant only for inputs, and allows for control of how the value
 * provided in parameter maps is to be written into the element input, effectively allowing
 * inputs to refer to values within complex types.
 *
 * For example, consider an input parameter whose JSON representation has the following
 * structure:
 *   type ExampleInput = {
 *     plateTypes: string[];
 *     wellCoords: string[];
 *   }
 * and an example value
 *   {
 *     "plateTypes": ["plateType1", "plateType2"],
 *     "wellCoords": ["A1", "A2"]
 *   }
 *
 * `extraKeys` may have a number of values, such as:
 *   * `extraKeys = undefined` or `extraKeys = []`
 *     * Expected Type: `ExampleInput`
 *     * Effect: The entire parameter is replaced by the value provided
 *   * `extraKeys = ["plateTypes"]`
 *     * Expected Type: `string[]`
 *     * Effect: the value of "plateTypes" is replaced by the value provided
 *   * `extraKeys = ["plateTypes", "1"]`
 *     * Expected Type = `string`
 *     * Effect: the value of `plateTypes[1]` (i.e. "plateType2") is replaced by the value provided
 *   * `extraKeys = ["plateTypes", "-1"]`
 *     * Expected Type = `string[]`
 *     * Effect: the values provided are appended to the existing array at `plateTypes`
 */
export function newElementPath(
  elementId: string,
  parameterName: string,
  extraKeys?: string[],
): ElementPath {
  return ['element', elementId, parameterName, ...(extraKeys || [])];
}

export function isStageDevicesPath(path: Path): path is StageDevicesPath {
  return path.length >= 2 && path[StageDevicesPathIndex.LABEL] === 'stageDevices';
}

export function getStageId(path: Path): string | undefined {
  if (isStageDevicesPath(path)) {
    return path[StageDevicesPathIndex.STAGE_ID];
  }
  return;
}

export function getStageConfiguredDeviceTail(path: Path): string[] | undefined {
  if (isStageDevicesPath(path)) {
    return path.slice(StageDevicesPathIndex.TAIL_START).filter(isDefined);
  }
  return;
}

const enum StageDevicesPathIndex {
  LABEL = 0,
  STAGE_ID = 1,
  TAIL_START = 2,
  TAIL_EXTRA_KEYS_START = 3,
}

/**
 * Describes the path of a stages configured devices.
 */
export type StageDevicesPath = [
  label: 'stageDevices',
  stageId: string,
  ...configuredDeviceTail: ConfiguredDeviceTail,
];

export type ConfiguredDeviceTail = [configuredDeviceId?: string, ...extraKeys: string[]];

export function getConfiguredDeviceTailExtraKeys(
  tail: ConfiguredDeviceTail,
): string[] | undefined {
  return tail.slice(1).filter(isDefined);
}

/**
 * creates a new StageDevicesPath which can be read by antha-core.
 *
 * 'stageId' is required to identify the configured devices within the stage.
 *
 * 'configuredDeviceKeys' if provided enables specific properties of a
 * configured device within the stage to be modified rather than replacing all
 * of the configured devices for the stage.
 *
 * For example, consider an stage parameter whose JSON representation has the
 * following structure:
 * ```
 * 	"Config": {
 *		"configuredDevices": [
 *			{
 *				"id": "config-id-0",
 *				"type": "Manual",
 *				"model": "Manual",
 *				"deviceId": "device-id-0",
 *				"inputPlateTypes": ["plateType96"]
 *			},
 *			{
 *				"id": "config-id-1",
 *				"type": "Manual",
 *				"model": "Manual",
 *				"deviceId": "device-id-0"
 *			}
 *		]
 *	},
 *	"Stages": [
 *		{
 *			"id": "stage-id-0",
 *			"name": "Stage A",
 *			"elementIds": ["element-1"],
 *			"configuredDevices": ["config-id-0"]
 *		},
 *		{
 *			"id": "stage-id-1",
 *			"name": "Stage B",
 *			"elementIds": ["element-2"],
 *			"configuredDevices": ["config-id-1"]
 *		}
 *	]
 * ```
 * and an example value
 * ```
 *   {
 *			 "id": "config-id-0",
 *			 "type": "Manual",
 *			 "model": "Manual",
 *			 "deviceId": "device-id-0",
 *       "inputPlateTypes": ["plateType384", "plateType1536"]
 *   }
 * ```
 * `configuredDeviceTail` may have a number of values, such as:
 *   * `configuredDeviceTail = undefined` or `configuredDeviceTail = []`
 *     * Expected Type: `ConfiguredDevice[]`
 *     * Effect: The entire stage's devices are replaced by the value provided
 *   * `configuredDeviceTail = ["config-id-0"]`
 *     * Expected Type: `ConfiguredDevice`
 *     * Effect: the value of Config.configuredDevices[0] is replaced by the value provided
 *   * `extraKeys = ["config-id-0", "inputPlateTypes"]`
 *     * Expected Type = `string[]`
 *     * Effect: the value of `inputPlateTypes` is replaced by the value provided
 *   * `extraKeys = ["config-id-0", "inputPlateTypes", "0"]`
 *     * Expected Type = `string`
 *     * Effect: the value of `inputPlateTypes[0]` (i.e. "plateType96") is replaced by the value provided
 */
export function newStageDevicesPath(
  stageId: string,
  configuredDeviceTail?: ConfiguredDeviceTail,
): StageDevicesPath {
  return ['stageDevices', stageId, ...(configuredDeviceTail || [])];
}

/**
 * ParameterMap provides values for the set of inputs or outputs defined in
 * the Schema section of a workflow
 */
export type ParameterMap = {
  [id: string]: any;
};
